import Modal from 'react-bootstrap/Modal';

function ModalOtherProject({ modal, setModal, title, textInfo, img }) {

    const pictureOne = require('../../img/otherProjects/fullScreen/utep-project.png');
    const pictureTwo = require('../../img/carFed/Dashboard.png');
    const pictureThree = require('../../img/otherProjects/fullScreen/g-cars.png');


    return (
        <>
            <Modal show={modal} onHide={setModal}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                        <h1 style={{
                            fontFamily: 'SoraBold', color: 'black', fontSize: '45px',
                            textAlign: 'center'
                        }}>
                            {title}
                        </h1>

                        <p style={{ fontFamily: 'Soralight', fontSize: '12px', textAlign: 'center' }}>
                            {textInfo}
                        </p>

                        {img == 'firstProject' ?
                            <img style={{ marginBottom: '20px', width: '100%', borderRadius: '10px' }} src={pictureOne} />
                            : img == 'secondProject' ?
                            <img style={{ marginBottom: '20px', width: '100%', borderRadius: '10px' }} src={pictureTwo} />
                                :
                                <img style={{ width: '100%' }} src={pictureThree} />

                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalOtherProject