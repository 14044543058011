
import HeaderComponent from './components/header';
import IntroductionComponent from './components/introduction';
import MyWorkComponent from './components/work';
import OtherProjects from './components/otherProjects';
import FooterComponent from './components/footer'

import './App.css';

function App() {
  return (
    <div className="App">
      <HeaderComponent />
      <IntroductionComponent />
      <MyWorkComponent />
      <OtherProjects />
      <FooterComponent />
    </div>
  );
}

export default App;
