import { useEffect } from 'react';

function IntroductionComponent() {

    return (
        <center>
            <div className="introductionContainer" id='home'>
                <div className="textIntroduction">
                    <h1>Hola! I am</h1>
                    <h1>Oliver Garcia</h1>

                    <p>Full Stack Software developer from Texas, USA. Sophomore College student with a passion for technology looking to grow its knowledge.</p>
                </div>
                <div className="picIntroduction">
                    <img src={require('../img/appleMe.png')} />
                </div>

            </div>

            <div class="typewriter">
                <p class="typing-erase">ojgarciacam@miners.utep.edu</p>
            </div>

            <center id='aboutMe'>
                <div className='aboutMeContainer'>
                    <div className='infoAboutMe'>
                        <h1>ABOUT ME</h1>
                        <div>
                            <img src={require('../img/profilePic.png')} />
                            <p>As a Sophomore College student with a passion for computer science, I have always been fascinated by the endless possibilities that technology offers. Throughout my academic journey, I have completed projects that have allowed me to hone my skills in programming, web development, and software engineering. From creating simple websites to designing systems and mobile apps, I have gained a wealth of experience and knowledge that has prepared me for a career in software engineering. With a strong work ethic and an unwavering commitment to excellence, I am confident that I will continue to excel in this field and make a meaningful contribution to the world of technology.</p>
                        </div>
                    </div>

                    <div className='containerAboutMeSkills'>
                        <div className='pieceSkill'>
                            <div className='imgAboutMeSkills'>
                                <img src={require('../img/frontend.png')} />
                            </div>
                            <h2>Languages</h2>
                            <div className='listSkills'>
                                <h3>Python</h3>
                                <h3>JavaScript</h3>
                                <h3>Java</h3>
                                <h3>HTML</h3>
                                <h3>CSS</h3>
                            </div>
                        </div>

                        <div className='pieceSkill'>
                            <div className='imgAboutMeSkills'>
                                <img src={require('../img/skills.png')} />
                            </div>
                            <h2>Frameworks</h2>
                            <div className='listSkills'>
                                <h3>React.js</h3>
                                <h3>React Native</h3>
                                <h3>Angular</h3>
                                <h3>Flask</h3>
                                <h3>Node.js</h3>
                                <h3>Bootstrap</h3>
                            </div>
                        </div>

                        <div className='pieceSkill'>
                            <div className='imgAboutMeSkills'>
                                <img src={require('../img/backend.png')} />
                            </div>
                            <h2>Databases/Tools</h2>
                            <div className='listSkills'>
                                <h3>MySQL</h3>
                                <h3>NoSQL</h3>
                                <h3>Git</h3>
                                <h3>Docker</h3>
                                <h3>Firebase</h3>
                                <h3>Figma</h3>
                                <h3>XCode</h3>
                                <h3>Android Studio</h3>
                                <h3>AWS</h3>
                            </div>
                        </div>
                    </div>


                    <hr className="invisibleHr" />
                    <hr className="invisibleHr" />
                    <hr className="invisibleHr" />

                </div>
            </center>

        </center>
    )
}

export default IntroductionComponent