import Carousel from 'react-bootstrap/Carousel';

function SecondProjectCarousel() {
    return (
        <>
            <Carousel variant="dark">
                <Carousel.Item interval={4000}>
                    <img
                        className="d-block w-100"
                        src={require('../../img/faceRecognition/Dashboard.png')}
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                    <img
                        className="d-block w-100"
                        src={require('../../img/faceRecognition/FaceRecognition.png')}
                        alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                    <img
                        className="d-block w-100"
                        src={require('../../img/faceRecognition/Employees.png')}
                        alt="Third slide"
                    />
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                    <img
                        className="d-block w-100"
                        src={require('../../img/faceRecognition/Reports.png')}
                        alt="Fourth slide"
                    />
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                    <img
                        className="d-block w-100"
                        src={require('../../img/faceRecognition/Message.png')}
                        alt="Fifth slide"
                    />
                </Carousel.Item>
            </Carousel>
        </>
    );
}

export default SecondProjectCarousel;