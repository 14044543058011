function HeaderComponent() {
    const linkedinURL = 'https://www.linkedin.com/in/oliverjgc'
    const githubURL = 'https://github.com/OliverJGC'

    return (
        <center>
            <header>
                <h1>OliverJGC</h1>

                <nav>
                    <a href="#home">Home</a>
                    <a href="#aboutMe">About Me</a>
                    <a href="#myWork">My Work</a>
                    <a href="#otherProjects">Other Projects</a>
                </nav>

                <div className='containerIconsHeader'>
                    <a href={linkedinURL}>
                        <img src={require('../img/icons/linkedinIcon.png')} alt="LinkedIn" />
                    </a>

                    <a href={githubURL}>
                        <img src={require('../img/icons/githubIcon.png')} alt="GitHub" />
                    </a>
                </div>

                {/* <button className="buttonCV">View CV</button> */}
            </header>
        </center>
    )
}

export default HeaderComponent