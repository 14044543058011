import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import FirstProjectCarousel from '../carousel/firstProjectCarousel';
import SecondProjectCarousel from '../carousel/secondProjectCarousel';

function ModalMainProject({ modal, setModal, title, textInfo }) {

    const [sizeModal, setModalSize] = useState('sg')
    const [changeView, setChangeView] = useState('Mobile')

    return (
        <>
            <Modal size={sizeModal} show={modal} onHide={setModal}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                        <h1 style={{
                            fontFamily: 'SoraBold', color: 'black', fontSize: '45px',
                            textAlign: 'center'
                        }}>
                            {title}
                        </h1>

                        <p style={{ fontFamily: 'Soralight', fontSize: '12px', textAlign: 'center' }}>
                            {textInfo}
                        </p>

                        {title == 'Daycare-Routes Software Management' ?
                            <FirstProjectCarousel changeView={changeView} setChangeView={setChangeView} setModalSize={setModalSize} />
                            : title == 'Face-Recognition Attendance Software' ?
                                <SecondProjectCarousel />
                                :
                                <></>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalMainProject