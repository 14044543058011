import { useState } from 'react'
import ModalOtherProject from './modal/modalOtherProject';

/*Create a function what iterates a loop 5 times*/

function OtherProjects() {

    const [modal, setModal] = useState(false);
    const [title, setTitle] = useState('');
    const [textInfo, setTextInfo] = useState('');
    const [img, setImg] = useState('');

    const activateFirstProject = () => {
        setTitle('Data Mining Utep')
        setTextInfo('Website for a UTEP project that involved the analysis of tweets using an algorithm. The website was designed to provide statistics and valuable data that could be useful for a variety of purposes. The algorithm collected and analyzed tweets based on specific keywords or phrases, and then provided detailed insights on sentiment analysis, trending topics, and other important metrics. The website was developed with an intuitive user interface, allowing users to easily interact with and explore the data.')
        setImg('firstProject')
        setModal(true)
    }

    const activateSecondProject = () => {
        setTitle('CarFed')
        setTextInfo('The mobile app system enables staff to efficiently track changes made in different cars. With this app, staff can easily add new cars and record any changes made to them such as oil changes, wheel replacements, and other maintenance tasks. The app also generates comprehensive reports detailing the changes made and future maintenance tasks that need to be completed.')
        setImg('secondProject')
        setModal(true)
    }

    const activateThirdProject = () => {
        setTitle('G-Cars')
        setTextInfo('Website dedicated to a car business that not only sells cars but also provides essential services such as license plates, nationalization, and permits. This website is designed to be user-friendly, providing customers a browsing through the available cars, choosing the ones they want to purchase, and being able to contact the seller.')
        setImg('thirdProject')
        setModal(true)
    }


    return (
        <>
            <div class="otherProjectsContainer" id="otherProjects">
                <div class="contOtherProTop"></div>

                <div className="containerInfoOtherProjects">
                    <h1>Other Projects</h1>
                    <div className="containerActualProjects">

                        {/* <div className="boxshadow" onClick={() => { activateFirstProject() }}>
                            <img className='imgOtherProjects' src={require('../img/otherProjects/showUtepProject.png')} />
                            <h2>Bloomberg Tech Lab</h2>

                            <p>Application in collaboration with Bloomberg engineers to create a project about creating a reliable, real-time data communication system between different components of the application, using RabbitMQ for messaging and Docker for deployment." 
                            </p>
                            <button>More Info</button>

                            <div className='containerIconSkillsOtherProjects'>

                                <img className='imgContttt' src={require('../img/icons/htmlIcon128.png')} />
                                <img src={require('../img/icons/cssIcon128.png')} />
                                <img src={require('../img/icons/javaScriptIcon128.png')} />
                                <img src={require('../img/icons/reactJsIcon128.png')} />
                            </div>
                        </div> */}

                        <div className="boxshadow" onClick={() => { activateFirstProject() }}>
                            <img className='imgOtherProjects' src={require('../img/otherProjects/showUtepProject.png')} />
                            <h2>Data Mining UTEP</h2>

                            <p>Website for a UTEP project that involved the analysis of tweets using an algorithm. The website was designed to provide statistics and valuable data that could be useful for a variety of purposes. The website was developed with an intuitive user interface, allowing users to easily interact with and explore the data.</p>
                            <button>More Info</button>

                            <div className='containerIconSkillsOtherProjects'>

                                <img className='imgContttt' src={require('../img/icons/htmlIcon128.png')} />
                                <img src={require('../img/icons/cssIcon128.png')} />
                                <img src={require('../img/icons/javaScriptIcon128.png')} />
                                <img src={require('../img/icons/reactJsIcon128.png')} />
                            </div>
                        </div>


                        <div className="boxshadow" onClick={() => { activateSecondProject() }}>
                            <img className='imgOtherProjects' src={require('../img/otherProjects/showCarFed.png')} />
                            <h2>Car Fed</h2>

                            <p>The mobile app system enables staff to efficiently track changes made in different cars. With this app, staff can easily add new cars and record any changes made to them such as oil changes, wheel replacements, and other maintenance tasks. The app also generates comprehensive reports detailing the changes made and future maintenance tasks that need to be completed.</p>
                            <button>More Info</button>

                            <div className='containerIconSkillsOtherProjects'>
                                <img src={require('../img/icons/reactJsIcon128.png')} />
                                <img src={require('../img/icons/firebaseIcon128.png')} />
                            </div>
                        </div>

                        <div className="boxshadow" onClick={() => { activateThirdProject() }}>
                            <img className='imgOtherProjects' src={require('../img/otherProjects/showGcars.png')} />
                            <h2>G-Cars Website</h2>

                            <p>Website dedicated to a car business that not only sells cars but also provides essential services such as license plates, nationalization, and permits. This website is designed to be user-friendly, providing customers a browsing through the available cars, choosing the ones they want to purchase, and being able to contact the seller.</p>
                            <button>More Info</button>

                            <div className='containerIconSkillsOtherProjects'>

                                <img className='imgContttt' src={require('../img/icons/htmlIcon128.png')} />
                                <img src={require('../img/icons/cssIcon128.png')} />
                                <img src={require('../img/icons/javaScriptIcon128.png')} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <ModalOtherProject modal={modal} setModal={setModal}
                title={title} textInfo={textInfo} img={img} />
        </>
    )
}

export default OtherProjects